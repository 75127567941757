import React, { Component } from 'react'
import Grid from './Grid'
import Datetime from 'react-datetime'
import preloader from '../images/load.svg'



export class Table extends Component {

    state = {
        data: '',
        fromDate: new Date(new Date().getFullYear(), 0, 1).getTime(),
        toDate: '',
        table: 'income',
        staff: '',
        servedBy: '---',
        dataLoaded: false,
        showedAll: false
    }


    getData = (token, fromDate, toDate, table, person, dispatch) => {

        this.setState({ dataLoaded: false })

        let _fromDate = fromDate === '' || isNaN(fromDate) ? '' : new Date(fromDate).toISOString();
        let _toDate = toDate === '' || isNaN(toDate) ? '' : new Date(toDate).toISOString();
        let _person = person === '---' ? '' : person

        fetch(`https://api-app.ecotrack.com.ua/api/${table}-records/?from_date=${_fromDate}&to_date=${_toDate}&person=${_person}`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {

                        localStorage.removeItem('token')
                        localStorage.removeItem('user_id')
                        dispatch({ type: "LOGOUT", payload: '' })

                    }
                    throw new Error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                if (data.results) {
                    this.setState({ data: data.results })
                } else {
                    this.setState({ data })
                }
                this.setState({ dataLoaded: true })
            })
            .catch(error => {
                console.log(error)
            })


    }

    setFromDate = (data) => {
        this.setState({ fromDate: data })
    }
    setToDate = (data) => {
        this.setState({ toDate: data })
    }

    deleteItem = (id, token, table, fromDate, toDate, person, dispatch, item) => {
        fetch(`https://api-app.ecotrack.com.ua/api/${table}-records/${id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Token ${token}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ deleted: true })
        })
            .then(response => {
                if (!response.ok) {
                    return new Error(response.status)
                } else {
                    return response
                }
            })
            .then(() => {
                this.getData(token, fromDate, toDate, table, person, dispatch)
            })
            .catch(error => {
                console.log(error)
            })

    }

    componentDidMount() {
        const { token, dispatch } = this.props
        const { fromDate, toDate, table, servedBy } = this.state

        this.getData(token, fromDate, toDate, table, servedBy, dispatch)

        fetch(`https://api-app.ecotrack.com.ua/api/staff/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {

                        localStorage.removeItem('token')
                        localStorage.removeItem('user_id')
                        dispatch({ type: "LOGOUT", payload: '' })

                    }
                    throw new Error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                this.setState({ staff: data })
            })
            .catch(error => {
                console.log(error);
            })


    }




    render() {

        const { data, table, fromDate, toDate, staff, servedBy, dataLoaded } = this.state
        const { token, dispatch, isSuperUser } = this.props

        let rents = 0
        let money = 0

        if (data && data.length !== 0 && Array.isArray(data)) {
            data.forEach((item) => {
                if (item.rents !== null && !item.deleted) {
                    rents += item.rents
                }
                if (item.money !== null && !item.deleted) {
                    money += item.money
                }
            })
        }

        if (!isSuperUser) {
            return null;
        }

        return (
            <div className="Table">
                <div className="page-title">
                    Таблиця
                    <div className="select-wrap">
                        <select
                            required
                            value={table}
                            onChange={e => {
                                this.setState({ table: e.target.value })
                                this.setState({ fromDate: new Date(new Date().getFullYear(), 0, 1).getTime() })
                                this.setState({ toDate: '' })
                                this.setState({ servedBy: '---' })
                                this.getData(token, new Date(new Date().getFullYear(), 0, 1).getTime(), '', e.target.value, '---', dispatch)
                            }}
                        >
                            <option value='income'>доходів</option>
                            <option value='cost'>витрат</option>

                        </select>
                    </div>
                </div>
                <div className="table-topside">

                    <div className="table-filter">
                        <div className="filter-item">
                            <div className="filter-item-label">з дати</div>
                            <div className="filter-input-wrap">
                                <div className="filter-input input-date">
                                    <Datetime
                                        timeFormat={false}
                                        locale='uk'
                                        defaultValue={''}
                                        value={fromDate}
                                        onChange={e => {
                                            this.setState({ fromDate: new Date(e._d).setHours(0, 0, 0, 0) })
                                            this.getData(token, new Date(e._d).setHours(0, 0, 0, 0), toDate, table, servedBy, dispatch)
                                        }}
                                        inputProps={{ readOnly: true }}

                                    />
                                </div>
                                <div className="filter-input-clear">
                                    <i
                                        className="fas fa-times"
                                        onClick={() => {
                                            this.setState({ fromDate: '' })
                                            this.getData(token, '', toDate, table, servedBy, dispatch)

                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className="filter-item">
                            <div className="filter-item-label">по дату</div>
                            <div className="filter-input-wrap">

                                <div className="filter-input input-date">
                                    <Datetime
                                        timeFormat={false}
                                        locale='uk'
                                        defaultValue={''}
                                        value={toDate}
                                        onChange={e => {
                                            this.setState({ toDate: new Date(e._d).setHours(23, 59, 59, 999) })
                                            this.getData(token, fromDate, new Date(e._d).setHours(23, 59, 59, 999), table, servedBy, dispatch)

                                        }}
                                        inputProps={{ readOnly: true }}

                                    />
                                </div>
                                <div className="filter-input-clear">
                                    <i
                                        className="fas fa-times"
                                        onClick={() => {
                                            this.setState({ toDate: '' })
                                            this.getData(token, fromDate, '', table, servedBy, dispatch)

                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className="filter-item">
                            <div className="filter-item-label">людина</div>
                            <div className="filter-input-wrap">

                                <div className="filter-input input-date">
                                    <select
                                        required
                                        value={servedBy}
                                        onChange={e => {
                                            this.setState({ servedBy: e.target.value })
                                            this.getData(token, fromDate, toDate, table, e.target.value, dispatch)

                                        }}
                                    >
                                        {staff.length !== 0 && Array.isArray(staff) ? staff.map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>{item.name}</option>
                                            )
                                        }) : null}
                                    </select>
                                </div>
                                <div className="filter-input-clear">
                                    <i
                                        className="fas fa-times"
                                        onClick={() => {
                                            this.setState({ servedBy: '---' })
                                            this.getData(token, fromDate, toDate, table, '---', dispatch)

                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-statistics">
                        {isSuperUser ?
                            <>
                                {table === 'income' ?
                                    <div className="table-statistics-row">
                                        <div className="table-statistics-row-label">Прокатів:</div>
                                        <div className="table-statistics-row-value">
                                            {!isNaN(rents) && dataLoaded ? rents :
                                                <img
                                                    className="preloader-s"
                                                    src={preloader}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                    </div>
                                    : null}
                                <div className="table-statistics-row">
                                    <div className="table-statistics-row-label">Cума:</div>
                                    <div className="table-statistics-row-value">
                                        {!isNaN(money) && dataLoaded ? money :
                                            <img
                                                className="preloader-s"
                                                src={preloader}
                                                alt=""
                                            />
                                        }
                                        <span>&#8372;</span></div>
                                </div>
                            </>
                            : null}
                    </div>
                </div>
                {dataLoaded ?
                    <Grid
                        dispatch={dispatch}
                        fromDate={fromDate}
                        toDate={toDate}
                        servedBy={servedBy}
                        deleteItem={this.deleteItem}
                        token={token}
                        data={data}
                        displayDate={true}
                        table={table}
                        getData={this.getData}
                        setFromDate={this.setFromDate}
                        setToDate={this.setToDate}
                    />
                    :
                    <img
                        className="preloader-s preloader-center"
                        src={preloader}
                        alt=""
                    />
                }
            </div>
        )
    }
}

export default Table
