import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class CustomerCardItem extends Component {
    state = {
        isOpen: false
    }

    render() {

        const { customer_name, customer_phone, costs, incomes, id, balance } = this.props.data
        const { isMobile, search } = this.props
        const { isOpen } = this.state


        const incomesWithType = incomes.map(i => {
            i['type'] = 'income'
            return i
        })

        const costsWithType = costs.map(i => {
            i['type'] = 'cost'
            return i
        })

        let history = incomesWithType.concat(costsWithType)
        history.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
        })



        return (
            <div className={isOpen ? "SparePartItem SparePartItem-open grid-row-wrap" : "SparePartItem grid-row-wrap"}>

                {!isMobile ?

                    <>
                        <div className="grid-row" onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}>

                            <div className="grid-cell grid-cell-name">
                                {customer_name}
                            </div>
                            <div className="grid-cell grid-cell-name">
                                {customer_phone}
                            </div>
                            <div className="grid-cell grid-cell-amount grid-cell-textcetner">
                                {balance}
                            </div>
                            <div className="grid-cell grid-cell-descr"></div>
                            <div className="grid-cell grid-cell-actions">

                                <Link to={`customer-card-income/${id}`} className="cell-action-add cell-action-quantity">
                                    <i className="fas fa-plus"></i>
                                </Link>
                                <Link to={`customer-card-cost/${id}`} className={balance <= 0 ? "cell-action-remove cell-action-quantity cell-action-disabled" : "cell-action-remove cell-action-quantity"}>
                                    <i className="fas fa-minus"></i>
                                </Link>
                                <div
                                    className="cell-action cell-action-toggle"
                                    onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}
                                >
                                    {isOpen ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>}
                                </div>
                            </div>
                        </div>

                        {isOpen ?

                            <div className="grid-extra no-padding">

                                {history.length === 0 ?
                                    <div className="grid-title">Історія відсутня</div>
                                    : null}


                                {history.length !== 0 ?

                                    <>

                                        <div className="grid-row grid-row-extra grid-row-extra-head">
                                            <div className="grid-cell grid-cell-name">
                                                дата
                                            </div>
                                            <div className={"grid-cell grid-cell-amount grid-cell-textcetner"}>
                                                кількість
                                            </div>
                                            <div className="grid-cell grid-cell-descr">
                                                комент
                                            </div>
                                            <div className="grid-cell grid-cell-actions"></div>

                                        </div>

                                        {history.map((item, index) => {

                                            let _date = new Date(item.date)
                                            let day = _date.getDate();
                                            let month
                                            let year = _date.getFullYear();


                                            if (_date.getMonth().toString().length === 1) {
                                                month = _date.getMonth() + 1 < 10 ? `0${_date.getMonth() + 1}` : _date.getMonth() + 1
                                            } else {
                                                month = _date.getMonth() + 1
                                            }

                                            let date = `${day}.${month}.${year}`


                                            return (

                                                <div className="grid-row grid-row-extra" key={index}>
                                                    <div className="grid-cell grid-cell-name">
                                                        {date}
                                                    </div>
                                                    <div className={`grid-cell grid-cell-amount grid-cell-textcetner ${item.type === 'cost' ? 'grid-cell-negative' : 'grid-cell-positive'}`}>
                                                        {item.type === 'cost' ? `-${item.quantity}` : `+${item.quantity}`}
                                                    </div>
                                                    <div className="grid-cell grid-cell-descr">
                                                        {item.description}
                                                    </div>
                                                    <div className="grid-cell grid-cell-actions"></div>



                                                </div>

                                            )
                                        })}
                                    </>



                                    : null}
                            </div>
                            : null}

                    </>
                    :
                    <>
                        <div className="grid-item" onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}>
                            <div className="grid-item-row">
                                <div className="grid-row-column">
                                    <div className="grid-name">
                                        {customer_name}
                                    </div>
                                    <div className="grid-name">
                                        {customer_phone}
                                    </div>
                                    <div className="grid-column-cell">
                                        <span className="grid-quantity">{`Баланс: ${balance}`}</span>
                                        <Link to={`customer-card-income/${id}`} className="cell-action-add cell-action-quantity">
                                            <i className="fas fa-plus"></i>
                                        </Link>
                                        <Link to={`customer-card-cost/${id}`} className={balance <= 0 ? "cell-action-remove cell-action-quantity cell-action-disabled" : "cell-action-remove cell-action-quantity"}>
                                            <i className="fas fa-minus"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {isOpen ?

                            <div className="grid-extra">

                                {history.length === 0 ?
                                    <div className="grid-title">Історія відсутня</div>
                                    : null}


                                {history.length !== 0 ?

                                    <>

                                        {history.map((item, index) => {

                                            let _date = new Date(item.date)
                                            let day = _date.getDate();
                                            let month
                                            let year = _date.getFullYear();


                                            if (_date.getMonth().toString().length === 1) {
                                                month = _date.getMonth() + 1 < 10 ? `0${_date.getMonth() + 1}` : _date.getMonth() + 1
                                            } else {
                                                month = _date.getMonth() + 1
                                            }

                                            let date = `${day}.${month}.${year}`


                                            return (

                                                <div className="grid-item-row" key={index}>
                                                    <div className="grid-item-date">
                                                        {date}
                                                    </div>
                                                    <div className={`grid-item-amount ${item.type === 'cost' ? 'grid-cell-negative' : 'grid-cell-positive'}`}>
                                                        {item.type === 'cost' ? `-${item.quantity}` : `+${item.quantity}`}
                                                    </div>
                                                    <div className="grid-item-description">
                                                        {item.description}
                                                    </div>



                                                </div>

                                            )
                                        })}
                                    </>



                                    : null}
                            </div>
                            : null}
                    </>

                }



            </div>
        )
    }
}

export default CustomerCardItem
