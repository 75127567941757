import React, { Component } from 'react'
import preloader from '../images/load.svg'


export class StorageIncome extends Component {

    state = {
        name: '',
        image: '',
        quantity: '',
        description: '',
        isQuantityValid: false,
        recordAdding: false
    }

    componentDidMount(){
        this.setState({item: this.props.match.params.id})
        this.setState({isNameLoaded: false})


        const { token, dispatch } = this.props

        fetch(`https://api-app.ecotrack.com.ua/api/storage-items/${this.props.match.params.id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(!response.ok){
                if(response.status === 401){

                    localStorage.removeItem('token')
                    localStorage.removeItem('user_id')
                    dispatch({type: "LOGOUT", payload: ''})
    
                }
                throw new Error(response.status)
            } else {
                return response.json()
            }
        })
        .then(data => {
            this.setState({name: data.name})
            if(data.image) {
                this.setState({image: data.image})
            } 
            this.setState({isNameLoaded: true})
        })

    }

    postRecord = () => {
        const { quantity, description, item } = this.state
        const { token, showNotification } = this.props

        let date = new Date().toISOString()

        this.setState({recordAdding: true})


        let data = {
            "date": date,
            "quantity": quantity,
            "description": description,
            "item": item
        }

        fetch('https://api-app.ecotrack.com.ua/api/storage-incomes/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if(!response.ok) {
                this.setState({quantity: ''})
                this.setState({description: ''})
                this.setState({isQuantityValid: false})
                this.setState({recordAdding: false})
                
                showNotification('помилка поповнення', 'error')



                return new Error(response.status)
            }
            else {
                this.setState({quantity: ''})
                this.setState({description: ''})
                this.setState({isQuantityValid: false})
                this.setState({recordAdding: false})

                showNotification('поповнено успішно', 'success')

            }
        })
    }

    render() {

        const { name, quantity, description, isQuantityValid, isNameLoaded, image, recordAdding } = this.state
        return (
            <div className="StorageIncome">
                <div className="page-title">Поповнення запчастини</div>

                <form
                    onSubmit={e=>{
                        e.preventDefault()
                        this.postRecord()
                    }}
                >

                    <div className="income-form">
                        <div className="form-row form-row-top">
                            <div className="form-row-label">Запчастина</div>
                            <div className="form-row-input">
                                {isNameLoaded ? 
                                    <>
                                    <p>{name}</p>
                                    {image? 
                                        <img className="form-storageitem-img" src={image} alt=""/>
                                    :null}
                                    </>
                                :
                                    <img
                                        className="preloader-s"
                                        src={preloader}
                                        alt=""
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Кількість поповнення</div>
                            <div className="form-row-input">
                                <input 
                                    type="number"
                                    pattern="\d*"
                                    min="1" 
                                    step="1"
                                    title="Тільки позитивні числа"
                                    value={quantity}
                                    onChange={e=>{
                                        this.setState({quantity: e.target.value})
                                        this.setState({isQuantityValid: e.target.checkValidity()})
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label">Комент</div>
                            <div className="form-row-input">
                                <input 
                                    type="text"
                                    value={description}
                                    onChange={e=>{
                                        this.setState({description: e.target.value})
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="add-record"
                        disabled={isQuantityValid && !recordAdding ? false : true}
                    >
                        {recordAdding ? 
                            <img
                                className="preloader-s preloader-center"
                                src={preloader}
                                alt=""
                            />
                        : 
                            'Поповнити'
                        }
                    </button>

                    
                </form>

            </div>
        )
    }
}

export default StorageIncome
