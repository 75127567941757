import React, { Component } from 'react'

export class Grid extends Component {

    getWeekDay = (date) => {
        let weekdays = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', `Пт`, 'Сб'];
        return weekdays[new Date(date).getDay()]
    }

    render() {
        const { data, displayDate, deleteItem, token, table, fromDate, toDate, servedBy, dispatch, getData, setFromDate, setToDate } = this.props

        return (
            <div className="Grid">

                {data.length !== 0 && Array.isArray(data) ?
                <div className="grid-wrap">
                    <div className="grid-row grid-row-header">
                        {displayDate? 
                            <div className="grid-cell grid-cell-date">
                                Дата
                            </div>
                        :null}
                        {'rents' in data[0] ? 

                            <div className="grid-cell grid-cell-time">
                                Час
                            </div>
                        :null}
                        <div className="grid-cell grid-cell-servedby">
                            {'rents' in data[0] ? 
                                "Обслуговував"
                            :"Людина"}
                        </div>
                        {'rents' in data[0] ? 
                            <div className="grid-cell">
                                Прокатів
                            </div>
                        :null}
                        <div className="grid-cell">
                            Сума
                        </div>
                        <div className="grid-cell grid-cell-descr">
                            Комент
                        </div>
                        <div className="grid-cell grid-cell-actions">
                            
                        </div>
                    </div>
                    {data.map((item, idx)=>{
                        let date = new Date(item.date)
                        let day = date.getDate();
                        let month
                        let year = date.getFullYear();

                        let time


                        if (date.getMonth().toString().length === 1){
                            month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1
                        } else {
                            month = date.getMonth()+1
                        }

                        if (date.getMinutes().toString().length === 1){
                            time = `${date.getHours()}:0${date.getMinutes()}`
                        } else {
                            time = `${date.getHours()}:${date.getMinutes()}`
                        }

                        let prevDate, _day, _month, _year

                        if(idx>0){

                            prevDate = new Date(data[idx-1].date)
                            _day = prevDate.getDate();
                            _year = prevDate.getFullYear();
    
    
                            if (prevDate.getMonth().toString().length === 1){
                                _month = prevDate.getMonth()+1 < 10 ? `0${prevDate.getMonth()+1}` : prevDate.getMonth()+1
                            } else {
                                _month = prevDate.getMonth()+1
                            }
                        }

                        let dateA = `${day}.${month}.${year}`
                        let dateB = `${_day}.${_month}.${_year}`

                        return (
                            
                        
                            <div key={idx} className={item.deleted ? "grid-row grid-row-delated" : (item.card_paid ? "grid-row grid-row-card-paid" : "grid-row")}>
                                {displayDate ? 
                                    <div 
                                        className="grid-cell grid-cell-date"
                                        onClick={(e)=>{
                                            if(e.target.dataset.date){
                                                let dataParts = e.target.dataset.date.split(".")
                                                setFromDate(
                                                    new Date(
                                                        `${dataParts[1]}.${dataParts[0]}.${dataParts[2]}`).setHours(0,0,0,0))
                                                setToDate(new Date(`${dataParts[1]}.${dataParts[0]}.${dataParts[2]}`).setHours(23,59,59,999))

                                                getData(token, new Date(`${dataParts[1]}.${dataParts[0]}.${dataParts[2]}`).setHours(0,0,0,0), new Date(`${dataParts[1]}.${dataParts[0]}.${dataParts[2]}`).setHours(23,59,59,999), table, servedBy, dispatch)
                                            }
                                        }}
                                    >
                                        {dateA !== dateB ?
                                            <div data-date={dateA}>
                                                {dateA}
                                                <span>{this.getWeekDay(date)}</span>
                                            </div>
                                        :null}
                                    </div>
                                :null}
                                {'rents' in data[0] ? 
                                    <div className="grid-cell grid-cell-time">
                                        {time}
                                    </div>
                                :null}
                                <div className="grid-cell grid-cell-servedby">
                                    {item.served_by !== null ? item.served_by.name : "---"}
                                </div>
                                {'rents' in data[0] ? 
                                    <div className="grid-cell grid-cell-textcetner">
                                        {item.rents}
                                    </div>
                                :null}
                                <div className="grid-cell grid-cell-textcetner">
                                    {item.money}
                                </div>
                                <div className="grid-cell grid-cell-descr">
                                    {item.description}
                                </div>
                                <div className="grid-cell grid-cell-actions">
                                    {!item.deleted ?
                                        <>
                                            <i 
                                                className="fas fa-times"
                                                onClick={e=>{
                                                    e.persist()
                                                    e.target.nextSibling.classList.remove('delete-confirm-hidden')
                                                    setTimeout(() => {
                                                        e.target.nextSibling.classList.add('delete-confirm-hidden')
                                                    }, 1000);
                                                }}
                                            ></i>
                                            <div 
                                                className="delete-confirm delete-confirm-hidden"
                                                onClick={(e)=>{
                                                    e.persist()
                                                    deleteItem(item.id, token, table, fromDate, toDate, servedBy, dispatch, item)
                                                    e.target.classList.add('delete-confirm-hidden')
                                                }}
                                            >
                                                видалити
                                            </div>
                                        </>
                                    :null}
                                </div>
                            </div>

                        )
                    })}
                </div>
                :null}
            </div>
        )
    }
}

export default Grid
