import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Grid from './Grid'
import preloader from '../images/load.svg'
import axios from 'axios'


export class Dashboard extends Component {

    state = {
        todayData: [],
        balance: '',
        todayDataLoaded: false,
        getBalanceLoaded: false,
        tariffPrice: '',
        cardPaid: ''
    }

    getMonth = (date) => {
        let months = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
        return months[new Date(date).getMonth()]
    }

    getTariffPrice = () => {
        let now = new Date()

        this.setState({
            tariffPrice: now.getDay() === 6 || now.getDay() === 0 ? 250 : 200
        })
    }

    getTodayData = (token, dispatch) => {

        let now = new Date()
        let startDay = new Date(now.setHours(0, 0, 0, 0)).toISOString()
        let endDay = new Date(now.setHours(23, 59, 59, 999)).toISOString()

        this.setState({ todayDataLoaded: false })


        axios.get(
            `https://api-app.ecotrack.com.ua/api/income-records/?from_date=${startDay}&to_date=${endDay}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then(response => {
                this.setState({ todayData: response.data })
                this.getCardPaid(response.data)
                this.setState({ todayDataLoaded: true })

            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                    dispatch({ type: "LOGOUT", payload: '' })
                }
            }
            )
    }

    deleteItem = (id, token, dispatch) => {
        fetch(`https://api-app.ecotrack.com.ua/api/income-records/${id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Token ${token}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ deleted: true })
        })
            .then(response => {
                if (!response.ok) {
                    return new Error(response.status)
                } else {
                    return response
                }
            })
            .then(() => {
                this.getTodayData(token, dispatch)
                this.getBalance(token, dispatch)

            })
    }

    getBalance = (token, dispatch) => {

        this.setState({ getBalanceLoaded: false })

        axios.get(
            `https://api-app.ecotrack.com.ua/api/balance/`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then(response => {
                this.setState({ balance: response.data[0].money })
                this.setState({ getBalanceLoaded: true })
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                    dispatch({ type: "LOGOUT", payload: '' })
                }
            }
            )
    }

    getCardPaid = (data) => {

        if (data) {
            let cardPaidData = data.filter(record => record.card_paid && !record.deleted)
            let cardPaid = 0
            cardPaidData.forEach(record => cardPaid += record.money)
            this.setState({ cardPaid })
        }
    }

    componentDidMount() {
        const { token, dispatch } = this.props
        this.getTodayData(token, dispatch)
        this.getBalance(token, dispatch)
        this.getTariffPrice()
        this.getCardPaid()
    }



    render() {

        const { todayData, todayDataLoaded, getBalanceLoaded, balance, tariffPrice, cardPaid } = this.state
        const { dispatch, token, isSuperUser } = this.props

        let now = new Date();
        let totalRents = 0;
        let totalMoney = 0;

        if (todayData.length !== 0 && Array.isArray(todayData)) {
            todayData.forEach((item) => {
                if (!item.deleted) {
                    totalRents += item.rents
                    totalMoney += item.money
                }
            })
        }

        return (
            <div className="Dashboard">
                <div className="page-title">
                    {`${this.getMonth(now)} ${now.getDate()}`}
                </div>
                <div className="current-tarrif">Тариф зараз: <span>{tariffPrice}&#8372;</span></div>
                <div className="dashboard-main">
                    <div className="leftside">
                        <div className="dashboard-statistics">
                            <div className="dashboard-statistics-row">
                                <div className="dashboard-statistics-row-label">Прокатів за день:</div>
                                <div className="dashboard-statistics-row-value">
                                    {todayDataLoaded ? totalRents :
                                        <img
                                            className="preloader-s"
                                            src={preloader}
                                            alt=""
                                        />
                                    }</div>
                            </div>
                            <div className="dashboard-statistics-row">
                                <div className="dashboard-statistics-row-label">Сума за день:</div>
                                <div className="dashboard-statistics-row-value">
                                    {todayDataLoaded ? totalMoney :
                                        <img
                                            className="preloader-s"
                                            src={preloader}
                                            alt=""
                                        />
                                    }
                                    <span>&#8372;</span>
                                </div>
                            </div>
                            <div className="dashboard-statistics-row">
                                <div className="dashboard-statistics-row-label">Безготівковий:</div>
                                <div className="dashboard-statistics-row-value">
                                    {todayDataLoaded ? cardPaid :
                                        <img
                                            className="preloader-s"
                                            src={preloader}
                                            alt=""
                                        />
                                    }
                                    <span>&#8372;</span>
                                </div>
                            </div>
                            <div className="dashboard-statistics-row">
                                <div className="dashboard-statistics-row-label">Каса:</div>
                                <div className="dashboard-statistics-row-value">
                                    {getBalanceLoaded ? balance :
                                        <img
                                            className="preloader-s"
                                            src={preloader}
                                            alt=""
                                        />
                                    }
                                    <span>&#8372;</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="rightside">

                        <div className="dashboard-actions-list">
                            <div className="dashboard-action">
                                <Link to="/new-income">
                                    Дохід
                                </Link>
                            </div>

                            <div className="dashboard-action">
                                <Link to="/new-cost">
                                    Витрата
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dashboard-grid">
                    <Grid
                        dispatch={dispatch}
                        deleteItem={this.deleteItem}
                        token={token}
                        data={todayData}
                        displayDate={false}
                    />
                </div>

            </div>
        )
    }
}

export default Dashboard
