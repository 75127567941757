import React, { Component } from 'react'
import Datetime from 'react-datetime'
import preloader from '../images/load.svg'




export class Cost extends Component {
    state = {
        datetime: '',
        servedBy: '---',
        money: '',
        description: '',
        staff: '',
        card_paid: false,
        removal: false,
        isDateTimeValid: true,
        isServedByValid: true,
        isMoneyValid: false,
        isDescriptionValid: false,
        recordAdding: false,
        staffLoaded: false
    }

    componentDidMount() {
        let now = new Date()
        this.setState({ datetime: now })

        const { token, dispatch } = this.props

        fetch(`https://api-app.ecotrack.com.ua/api/staff/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {

                        localStorage.removeItem('token')
                        localStorage.removeItem('user_id')
                        dispatch({ type: "LOGOUT", payload: '' })

                    }
                    throw new Error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                this.setState({ staff: data })
                this.setState({ staffLoaded: true })
            })
            .catch(error => {
                console.log(error);
            })



    }

    postRecord = () => {
        let { datetime, servedBy, money, description, card_paid } = this.state
        let { token, showNotification } = this.props

        let date = new Date(datetime).toISOString()

        this.setState({ recordAdding: true })


        let data = {
            "served_by": {
                "name": servedBy === '' ? null : servedBy
            },
            "date": date,
            "money": money,
            "card_paid": card_paid,
            "description": description
        }


        fetch('https://api-app.ecotrack.com.ua/api/cost-records/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ servedBy: '---' })
                    this.setState({ money: '' })
                    this.setState({ description: '' })
                    this.setState({ removal: false })
                    this.setState({ isDateTimeValid: false })
                    this.setState({ isDescriptionValid: false })
                    this.setState({ card_paid: false })
                    this.setState({ isMoneyValid: false })
                    this.setState({ isServedByValid: false })
                    this.setState({ recordAdding: false })

                    showNotification('помилка', 'error')

                    return new Error(response.status)

                }
                else {
                    this.setState({ servedBy: '---' })
                    this.setState({ money: '' })
                    this.setState({ description: '' })
                    this.setState({ removal: false })
                    this.setState({ isDateTimeValid: false })
                    this.setState({ card_paid: false })
                    this.setState({ isDescriptionValid: false })
                    this.setState({ isMoneyValid: false })
                    this.setState({ isServedByValid: false })
                    this.setState({ recordAdding: false })

                    showNotification('додано успішно', 'success')

                    this.props.history.push('/')

                }
            })

    }

    render() {

        const { datetime, servedBy, staff, money, description, isDateTimeValid, isMoneyValid, isDescriptionValid, isServedByValid, recordAdding, card_paid, removal, staffLoaded } = this.state

        return (
            <div className="Cost">
                <div className="page-title">Нова витрата</div>

                <form
                    onSubmit={e => {
                        e.preventDefault()
                        this.postRecord()
                    }}
                >

                    <div className="cost-form">
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Дата</div>
                            <div className="form-row-input input-date">
                                <Datetime
                                    locale='uk'
                                    defaultValue={new Date()}
                                    value={datetime}
                                    onChange={e => {
                                        this.setState({ datetime: new Date(e._d) })
                                        if (typeof e == 'object') {
                                            this.setState({ isDateTimeValid: e._isValid })
                                        } else {
                                            this.setState({ isDateTimeValid: false })
                                        }
                                    }}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Людина</div>
                            <div className="form-row-input">
                                {staffLoaded ?

                                    <select
                                        required
                                        value={servedBy}
                                        onChange={e => {
                                            this.setState({ servedBy: e.target.value })
                                            this.setState({ isServedByValid: e.target.checkValidity() })
                                        }}
                                    >
                                        {staff.length !== 0 && Array.isArray(staff) ? staff.map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>{item.name}</option>
                                            )
                                        }) : null}
                                    </select>
                                    :
                                    <img
                                        className="preloader-s"
                                        src={preloader}
                                        alt=""
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-row-label">Безготівковий</div>
                            <div className="form-row-input">
                                <button
                                    type='button'
                                    className={card_paid ? "input-checkbox input-checked" : "input-checkbox"}
                                    onClick={() => {
                                        this.setState({ card_paid: !this.state.card_paid })
                                        this.setState({ description: removal ? '' : description })
                                        this.setState({ removal: false })
                                    }}
                                >
                                    {card_paid ? <i className="fas fa-check checkbox-checked"></i> : <i className="fas fa-check"></i>}
                                </button>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-row-label form-row-required">Сума</div>
                            <div className="form-row-input">
                                <input
                                    required
                                    type="number"
                                    pattern="\d*"
                                    min="1"
                                    step="1"
                                    title="Тільки позитивні числа"
                                    value={money}
                                    onChange={e => {
                                        this.setState({ money: e.target.value })
                                        this.setState({ isMoneyValid: e.target.checkValidity() })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-row-label">Виїмка</div>
                            <div className="form-row-input">
                                <button
                                    type='button'
                                    className={removal ? "input-checkbox input-checked" : "input-checkbox"}
                                    onClick={() => {
                                        this.setState({ removal: !this.state.removal })
                                        this.setState({ card_paid: false })
                                        this.setState({ description: !removal ? 'Виїмка' : '' })
                                        this.setState({ isDescriptionValid: !removal ? true : false })
                                    }}
                                >
                                    {removal ? <i className="fas fa-check checkbox-checked"></i> : <i className="fas fa-check"></i>}
                                </button>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-row-label form-row-required">Комент</div>
                            <div className="form-row-input">
                                <input
                                    required
                                    type="text"
                                    value={description}
                                    onChange={e => {
                                        this.setState({ description: e.target.value })
                                        this.setState({ isDescriptionValid: e.target.checkValidity() })
                                    }}
                                    disabled={removal}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="add-record"
                        disabled={isDateTimeValid && isDescriptionValid && isMoneyValid && isServedByValid && !recordAdding ? false : true}
                    >
                        {recordAdding ?
                            <img
                                className="preloader-s preloader-center"
                                src={preloader}
                                alt=""
                            />
                            :
                            'Додати'
                        }
                    </button>

                </form>

            </div>
        )
    }
}

export default Cost
