import React, { Component } from 'react'

const Context = React.createContext()

const reducer = (state, action) => {

    switch (action.type) {

        case 'GET_PATH':
            return {
                ...state,
                path: action.payload
            }

        case 'LOGIN':
            return {
                ...state,
                isLogged: true,
                token: action.payload.token,
                user: action.payload.user,
                isSuperUser: action.payload.user.id === 1 || action.payload.user.id === 6 || action.payload.user.id === 4 || action.payload.user.id === 9
            }



        case 'LOGOUT':
            return {
                ...state,
                isLogged: false,
                token: '',
                user: null
            }

        case 'LOADING':
            return {
                ...state,
                isLoading: true
            }

        case 'LOADED':
            return {
                ...state,
                isLoading: false
            }

        case 'NOTIFICATION':
            return {
                ...state,
                notificationMessage: action.payload.message,
                notificationActive: action.payload.message,
                notificationType: action.payload.type,
            }

        default: return state
    }
}



export class Provider extends Component {

    state = {

        isLogged: false,
        token: '',
        isLoading: false,
        user: null,
        isSuperUser: false,
        path: '',
        notificationActive: false,
        notificationMessage: '',
        notificationType: '',
        notificationDuration: 4000,

        dispatch: action => {
            this.setState(state => reducer(state, action))
        },

        showNotification: (message, type) => {
            this.setState({ notificationActive: true })
            this.setState({ notificationMessage: message })
            this.setState({ notificationType: type })

            setTimeout(() => {
                this.setState({ notificationActive: false })
                setTimeout(() => {
                    this.setState({ notificationMessage: '' })
                    this.setState({ notificationType: '' })
                }, 300);
            }, this.state.notificationDuration);
        }

    }

    componentWillMount() {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("user"))

        if (
            typeof token !== 'undefined' &&
            token !== null &&
            token !== '' &&
            token !== 'undefined' &&
            user
        ) {
            this.setState({ isLogged: true });
            this.setState({ token })
            this.setState({ user })
            this.setState({ isSuperUser: user.id === 1 || user.id === 6 || user.id === 4 || user.id === 9 })
        }
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        )
    }


}

export const Consumer = Context.Consumer