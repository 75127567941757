import React, { Component } from 'react'
import axios from 'axios'
import Datetime from 'react-datetime'

export class WorkShiftsCard extends Component {

    state = {
        isAddingNewStaff: false,
        newStaff: '',
        wrongSelect: false,
        wrongTime: false,
        from_time: new Date().setHours(12, 0, 0, 0),
        to_time: new Date().setHours(20, 0, 0, 0)
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    getMonth = (date) => {
        let months = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
        return months[new Date(date).getMonth()]
    }

    getWeekDay = (date) => {
        let weekdays = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', `Пт`, 'Сб'];
        return weekdays[new Date(date).getDay()]
    }

    removeStaff = (record_id, getWorkShifts, today, token, dispatch, makeWorkShiftsLoading) => {

        makeWorkShiftsLoading()

        axios.delete(
            `https://api-app.ecotrack.com.ua/api/work-shifts/${record_id}/`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then(()=>{
                getWorkShifts(today, token, dispatch)
            })
            .catch(error =>{
                if (error.response){
                    if (error.response.status === 401){
                        localStorage.removeItem('token')
                        localStorage.removeItem('user')
                        dispatch({type: "LOGOUT", payload: ''})
                    }
                }
                console.log(error)
                getWorkShifts(today, token, dispatch)
            }
        )
    }

    addStaff = (getWorkShifts, today, from_time, to_time, date, token, dispatch, makeWorkShiftsLoading) => {

        let validSelect = this.state.newStaff !== '' && this.state.newStaff !== '---';
        let validTime = from_time < to_time;

        this.setState({wrongTime: false})
        this.setState({wrongSelect: false})


        if(validSelect && validTime){

            this.setState({isAddingNewStaff: false})

            makeWorkShiftsLoading()

            from_time = new Date(from_time)
            to_time = new Date(to_time)

            let from_date = new Date(new Date(date).setHours( from_time.getHours(), from_time.getMinutes(), 0, 0)).toISOString()
            let to_date = new Date(new Date(date).setHours( to_time.getHours(), to_time.getMinutes(), 0, 0)).toISOString()


            let data = {
                "staff": {
                    "name": this.state.newStaff === '' ? null : this.state.newStaff
                },
                "date_from": from_date,
                "date_to": to_date,
            }



            fetch('https://api-app.ecotrack.com.ua/api/work-shifts/', {
                method: 'post',
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(data)
            })
            .then((response) => {
                getWorkShifts(today, token, dispatch)
            })


        } else {
            if(!validSelect){
                this.setState({wrongSelect: true})
            } 
            
            if(!validTime){
                this.setState({wrongTime: true})
            }
        }
    }


    render() {

        const { day, token, dispatch, today, getWorkShifts, staff, newStaff, makeWorkShiftsLoading } = this.props
        const { isAddingNewStaff, wrongSelect, from_time, to_time, wrongTime } = this.state

        let dateDay = new Date(day.day).getDate()
        let dateMonth = this.getMonth(day.day)
        let dateWeekDay = this.getWeekDay(day.day)

        return (
            <div className="WorkShiftsCard">
                <div className="card-title">
                    <span>{dateWeekDay}</span>{`${dateDay} ${dateMonth}`}
                </div>
                <div className="card-staff">
                    {day.staffList.length > 0 ? 
                        (
                            <div className="card-staff-main">

                                {day.staffList.map((person, index) => {
    
                                    let timeDiff = (new Date(person.time_to) - new Date(person.time_from)) / 3600000;
                                    let width = (timeDiff *  100 / 8);
                                    let startGap = ((new Date(person.time_from) - new Date(new Date(person.time_from).setHours(12, 0, 0, 0))) / 3600000) * 100 / 8;
    
                                    return (
                                        <div key={index} className="card-staff-row">
                                            <div className="card-staff-item">
                                                {startGap !== 0 ? <span style={{width: `${startGap}%`}}></span> : null}
                                                <div 
                                                    className="card-staff-person" 
                                                    style={{backgroundColor: person.staff.color !== '' ? person.staff.color : '#dfdfdf', width: `${width}%`}}
                                                >
                                                    {person.staff.name}
                                                </div>
                                            </div>
                                            <div 
                                                className="card-staff-action"
                                                onClick={e=>{
                                                    e.persist()
                                                    let removeBtn = e.currentTarget;
                                                    removeBtn.nextSibling.classList.add('remove-confirm-active')
                                                    setTimeout(() => {
                                                        removeBtn.nextSibling.classList.remove('remove-confirm-active')
                                                    }, 800);
                                                }}
                                            >
                                                <i className="fas fa-minus"></i>
                                            </div>
                                            <div 
                                                className="remove-confirm"
                                                onClick={()=>{
                                                    this.removeStaff(person.record_id, getWorkShifts, today, token, dispatch, makeWorkShiftsLoading)
                                                }}
                                            >
                                                видалити
                                            </div>
    
                                        </div>
                                    )
                                })}
                                <div className="timeline">
                                    <span>12:00</span>
                                    <span></span>
                                    <span>14:00</span>
                                    <span></span>
                                    <span>16:00</span>
                                    <span></span>
                                    <span>18:00</span>
                                    <span></span>
                                    <span>20:00</span>
                                </div>
                            </div>

                        )
                        
                    : (
                        <div className="card-staff-row">
                            <div className="card-no-staff">пусто</div>
                        </div>
                    )}

                    {isAddingNewStaff ? 
                        (
                            <div className="card-staff-row">
                                <select
                                    required
                                    value={newStaff}
                                    onChange={e=>{
                                        this.setState({newStaff: e.target.value})
                                    }}
                                    className={wrongSelect ? 'wrong-select' : null}
                                >
                                    {staff.length !== 0 && Array.isArray(staff) ? staff.map((item, index)=>{
                                        return (
                                            <option key={index} value={item.name}>{item.name}</option>
                                        )
                                    }):null}
                                </select>
                                <Datetime
                                    timeFormat={true}
                                    dateFormat={false}
                                    locale='uk'
                                    defaultValue={''}
                                    value={from_time}
                                    onChange={e=>{
                                        this.setState({from_time: e._d})
                                    }}
                                    inputProps={{readOnly: true}}
                                    timeConstraints={
                                        {
                                            hours: { min: 12, max: 20, step: 1 },
                                            minutes: {step: 15}
                                        }
                                    }
                                    className={wrongTime ? 'invalid' : null}
                                />
                                <div>-</div>
                                <Datetime
                                    timeFormat={true}
                                    dateFormat={false}
                                    locale='uk'
                                    defaultValue={''}
                                    value={to_time}
                                    onChange={e=>{
                                        this.setState({to_time: e._d})
                                    }}
                                    inputProps={{readOnly: true}}
                                    timeConstraints={
                                        {
                                            hours: { min: 12, max: 20, step: 1 },
                                            minutes: {step: 15}
                                        }
                                    }
                                    className={wrongTime ? 'invalid' : null}
                                />
                                <div 
                                    className="card-staff-action card-staff-action-wide"
                                    onClick={()=>{
                                        this.addStaff(getWorkShifts, today, from_time, to_time, day.day, token, dispatch, makeWorkShiftsLoading)
                                    }}
                                >
                                    ok
                                </div>
                            </div>
                            
                        )
                    :
                        (
                            <div 
                                className="card-staff-action card-staff-action-alone"
                                onClick={()=>{
                                    this.setState({isAddingNewStaff: true})
                                }}
                            >
                                <i className="fas fa-plus"></i>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default WorkShiftsCard
