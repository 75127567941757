import React, { Component } from 'react'
import preloader from '../images/load.svg'


export class StorageIncome extends Component {

    state = {
        data: '',
        name: '',
        quantity: '',
        image: '',
        staff: '',
        item: '',
        taken_by: '---',
        description: '',
        recordAdded: null,
        isQuantityValid: false,
        isNameLoaded: false,
        recordAdding: false

    }

    componentDidMount(){
        this.setState({item: this.props.match.params.id})
        this.setState({isNameLoaded: false})


        const { token, dispatch } = this.props

        this.getData(token, dispatch, this.props.match.params.id)
        this.getStaff(token, dispatch)      

    }

    getData = (token, dispatch, id) => {
        fetch(`https://api-app.ecotrack.com.ua/api/storage-items/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(!response.ok){
                if(response.status === 401){

                    localStorage.removeItem('token')
                    localStorage.removeItem('user_id')
                    dispatch({type: "LOGOUT", payload: ''})
    
                }
                throw new Error(response.status)
            } else {
                return response.json()
            }
        })
        .then(data => {
            this.setState({name: data.name})
            this.setState({data: data})
            this.setState({isNameLoaded: true})
        })
        .catch(error => {
            console.log(error)
        })
    }

    getStaff = (token, dispatch) => {
        fetch(`https://api-app.ecotrack.com.ua/api/staff/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(!response.ok){
                if(response.status === 401){

                    localStorage.removeItem('token')
                    localStorage.removeItem('user_id')
                    dispatch({type: "LOGOUT", payload: ''})
    
                }
                throw new Error(response.status)
            } else {
                return response.json()
            }
        })
        .then(data => {
            this.setState({staff: data})
        })
        .catch(error => {
            console.log(error)
        })
    }

    postRecord = () => {
        const { quantity, description, item, taken_by } = this.state
        const { token, dispatch } = this.props


        let date = new Date().toISOString()

        this.setState({recordAdding: true})


        let data = {
            "taken_by": {
                "name": taken_by === '' ? null : taken_by
            },
            "date": date,
            "quantity": quantity,
            "description": description,
            "item": item
        }

        fetch('https://api-app.ecotrack.com.ua/api/storage-costs/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if(!response.ok) {
                this.setState({recordAdded: false})
                this.setState({quantity: ''})
                this.setState({taken_by: '---'})
                this.setState({description: ''})
                this.setState({isQuantityValid: false})
                this.setState({recordAdding: false})

                
                setTimeout(() => {
                    this.setState({recordAdded: null})
                }, 2000);
                return new Error(response.status)
            }
            else {
                this.setState({recordAdded: true})
                this.setState({quantity: ''})
                this.setState({taken_by: '---'})
                this.setState({description: ''})
                this.setState({isQuantityValid: false})
                this.setState({recordAdding: false})

                setTimeout(() => {
                    this.setState({recordAdded: null})
                }, 2000);
                this.getData(token, dispatch, item)

            }
        })

    }

    render() {

        const { name, quantity, staff, taken_by, description, recordAdded, isQuantityValid, data, isNameLoaded, recordAdding } = this.state

        let costs_total = 0
        let incomes_total = 0
        let balance = 0


        if(typeof data === 'object'){

            data.costs.map(i => {
                return costs_total += i.quantity
            });
            data.incomes.map(i => {
                return incomes_total += i.quantity
            });
    
            balance = incomes_total - costs_total
        }

        return (
            <div className="StorageIncome">
                <div className="page-title">Вилучення запчастини</div>

                <form
                    onSubmit={e=>{
                        e.preventDefault()
                        this.postRecord()
                    }}
                >

                    <div className="income-form">
                        <div className="form-row form-row-top">
                            <div className="form-row-label">Запчастина</div>
                            <div className="form-row-input">
                                {isNameLoaded ? 
                                    <>
                                    <p>{name}</p>
                                    {data.image? 
                                        <img className="form-storageitem-img" src={data.image} alt=""/>
                                    :null}
                                    </>
                                :
                                    <img
                                        className="preloader-s"
                                        src={preloader}
                                        alt=""
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Кількість вилученого</div>
                            <div className="form-row-input">
                                <input 
                                    type="number"
                                    pattern="\d*"
                                    min="1" 
                                    step="1"
                                    max={balance}
                                    placeholder={`доступно ${balance}`}
                                    title="Тільки позитивні числа"
                                    value={quantity}
                                    onChange={e=>{
                                        this.setState({quantity: e.target.value})
                                        this.setState({isQuantityValid: e.target.checkValidity()})
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-row-label form-row-required">Вилучив</div>
                            <div className="form-row-input">
                                <select
                                    required
                                    value={taken_by}
                                    onChange={e=>{
                                        this.setState({taken_by: e.target.value})
                                    }}
                                >
                                    {staff.length !== 0 && Array.isArray(staff) ? staff.map((item, index)=>{
                                        return (
                                            <option key={index} value={item.name}>{item.name}</option>
                                        )
                                    }):null}
                                </select>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-row-label">Комент</div>
                            <div className="form-row-input">
                                <input 
                                    type="text"
                                    value={description}
                                    onChange={e=>{
                                        this.setState({description: e.target.value})
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="add-record"
                        disabled={isQuantityValid && !recordAdding ? false : true}
                    >
                        {recordAdding ? 
                            <img
                                className="preloader-s preloader-center"
                                src={preloader}
                                alt=""
                            />
                        : 
                            'Вилучити'
                        }
                    </button>

                    {recordAdded !== null && recordAdded === false ?
                        <div className="form-wrong">
                            Помилка
                        </div>
                    :(recordAdded ? 
                        <div className="form-success">
                            Вилучено успішно
                        </div>
                    : null)}

                    
                </form>

            </div>
        )
    }
}

export default StorageIncome
