import React, { Component } from 'react'
import preloader from '../../images/load.svg'


export class CustomerCardNewIncome extends Component {

    state = {
        item: null,
        customerName: '',
        customerPhone: '',
        quantity: '',
        description: '',
        isQuantityValid: false,
        recordAdding: false,
        isDataLoaded: false
    }

    componentDidMount() {
        this.setState({ item: this.props.match.params.id })


        const { token, dispatch } = this.props

        fetch(`https://api-app.ecotrack.com.ua/api/customercards-items/${this.props.match.params.id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {

                        localStorage.removeItem('token')
                        localStorage.removeItem('user_id')
                        dispatch({ type: "LOGOUT", payload: '' })

                    }
                    throw new Error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                this.setState({ customerName: data.customer_name })
                this.setState({ customerPhone: data.customer_phone })
                this.setState({ isDataLoaded: true })
            })

    }

    postRecord = () => {
        const { quantity, description, item } = this.state
        const { token, showNotification } = this.props

        let date = new Date().toISOString()

        this.setState({ recordAdding: true })


        let data = {
            "date": date,
            "quantity": quantity,
            "description": description,
            "item": item
        }

        fetch(`https://api-app.ecotrack.com.ua/api/customercards-incomes/`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ quantity: '' })
                    this.setState({ description: '' })
                    this.setState({ isQuantityValid: false })
                    this.setState({ recordAdding: false })

                    showNotification('помилка внесення', 'error')



                    return new Error(response.status)
                }
                else {
                    this.setState({ quantity: '' })
                    this.setState({ description: '' })
                    this.setState({ isQuantityValid: false })
                    this.setState({ recordAdding: false })

                    showNotification('внесено успішно', 'success')

                }
            })
    }

    render() {

        const { customerName, customerPhone, quantity, description, isQuantityValid, isDataLoaded, recordAdding } = this.state
        return (
            <div className="StorageIncome">
                <div className="page-title">Поповнення балансу клієнта</div>

                <form
                    onSubmit={e => {
                        e.preventDefault()
                        this.postRecord()
                    }}
                >

                    <div className="income-form">
                        <div className="form-row form-row-top">
                            <div className="form-row-label">Гість</div>
                            <div className="form-row-input">
                                {isDataLoaded ?
                                    <>
                                        <p style={{ marginBottom: '5px' }}>{customerName}</p>
                                        <p>{customerPhone}</p>
                                    </>
                                    :
                                    <img
                                        className="preloader-s"
                                        src={preloader}
                                        alt=""
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Кількість поповнення</div>
                            <div className="form-row-input">
                                <input
                                    type="number"
                                    pattern="\d*"
                                    min="1"
                                    step="1"
                                    title="Тільки позитивні числа"
                                    value={quantity}
                                    onChange={e => {
                                        this.setState({ quantity: e.target.value })
                                        this.setState({ isQuantityValid: e.target.checkValidity() })
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label">Комент</div>
                            <div className="form-row-input">
                                <input
                                    type="text"
                                    value={description}
                                    onChange={e => {
                                        this.setState({ description: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="add-record"
                        disabled={isQuantityValid && !recordAdding ? false : true}
                    >
                        {recordAdding ?
                            <img
                                className="preloader-s preloader-center"
                                src={preloader}
                                alt=""
                            />
                            :
                            'Підтвердити'
                        }
                    </button>


                </form>

            </div>
        )
    }
}

export default CustomerCardNewIncome
