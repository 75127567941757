import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

export class Location extends Component {

  componentDidMount(){
    this.props.dispatch({type: "GET_PATH", payload: this.props.location.pathname})

  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
      this.props.dispatch({type: "GET_PATH", payload: this.props.location.pathname})
    }
  }
    
  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child);
    });

    
    return children
  
  }
}
    
export default withRouter(Location);