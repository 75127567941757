import React, { Component } from 'react'
import Datetime from 'react-datetime'
import preloader from '../images/load.svg'

export class Calculation extends Component {

    state = {
        data: '',
        sortedData: [],
        totalSalary: '',
        fromDate: new Date(new Date().getFullYear(), 0, 1).getTime(),
        toDate: '',
        staff: '',
        servedBy: '---',
        dataCalculated: true,
        fixedPersantage: 0.05
    }

    componentDidMount() {

        const { token, dispatch } = this.props

        fetch(`https://api-app.ecotrack.com.ua/api/staff/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {

                        localStorage.removeItem('token')
                        localStorage.removeItem('user_id')
                        dispatch({ type: "LOGOUT", payload: '' })

                    }
                    throw new Error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                this.setState({ staff: data })
            })
            .catch(error => {
                console.log(error);
            })
    }



    getData = (token, fromDate, toDate, person, dispatch) => {

        const { fixedPersantage } = this.state

        if (person !== '---') {

            this.setState({ dataCalculated: false })

            fetch(`https://api-app.ecotrack.com.ua/api/income-records/?from_date=${fromDate === '' || isNaN(fromDate) ? '' : new Date(fromDate).toISOString()}&to_date=${toDate === '' || isNaN(toDate) ? '' : new Date(toDate).toISOString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 401) {

                            localStorage.removeItem('token')
                            localStorage.removeItem('user_id')
                            dispatch({ type: "LOGOUT", payload: '' })

                        }
                        throw new Error(response.status)
                    } else {
                        return response.json()
                    }
                })
                .then(data => {
                    this.makeCalculation(data, fixedPersantage)
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }


    makeCalculation = (data, fixedPersantage) => {

        let sortedData = []
        let totalSalary = 0
        let selectedPerson = this.state.servedBy

        data.forEach(record => {
            let date = new Date(record.date).setHours(0, 0, 0, 0)

            if (!record.deleted && record.rents !== 0) {

                if (sortedData.some(e => e.date === date)) {
                    sortedData.forEach((i) => {
                        if (i.date === date) {
                            i.records.push(record)
                            i.incomeDay += record.money
                            i.rentsDay += record.rents
                        }
                    })
                } else {
                    sortedData.push(
                        {
                            date: date,
                            records: [record],
                            incomeDay: record.money
                        }
                    )
                }

            }

        });

        sortedData = sortedData.filter(day => {

            if (day.records.some(record => record.served_by ? record.served_by.name === selectedPerson : false)) {

                let _payoff = 300
                let selectedPersonIncome = 0
                let selectedPersonRents = 0

                day.records.forEach(record => {
                    if (record.served_by ? record.served_by.name === selectedPerson : false) {
                        selectedPersonIncome += record.money
                        selectedPersonRents += record.rents
                    }
                })

                let daySalary = Math.round((selectedPersonIncome * fixedPersantage) + _payoff)

                totalSalary += daySalary

                day['personIncome'] = selectedPersonIncome
                day['personRents'] = selectedPersonRents
                day['salary'] = daySalary
                day['payoff'] = _payoff

                return day

            } return false
        })

        this.setState({ totalSalary })
        this.setState({ sortedData })
        this.setState({ dataCalculated: true })

    }


    render() {

        const { fromDate, toDate, staff, servedBy, sortedData, dataCalculated, totalSalary } = this.state
        const { token, dispatch, user, isSuperUser } = this.props

        return (
            <div className="Calculation">
                <div className="page-title">
                    Розрахунок
                </div>

                {user && isSuperUser ?

                    <>

                        <div className="table-topside">

                            <div className="table-filter">
                                <div className="filter-item">
                                    <div className="filter-item-label">з дати</div>
                                    <div className="filter-input-wrap">
                                        <div className="filter-input input-date">
                                            <Datetime
                                                timeFormat={false}
                                                locale='uk'
                                                defaultValue={''}
                                                value={fromDate}
                                                onChange={e => {
                                                    this.setState({ fromDate: new Date(e._d).setHours(0, 0, 0, 0) });
                                                    this.getData(token, new Date(e._d).setHours(0, 0, 0, 0), toDate, servedBy, dispatch)
                                                }}
                                                inputProps={{ readOnly: true }}

                                            />
                                        </div>
                                        <div className="filter-input-clear">
                                            <i
                                                className="fas fa-times"
                                                onClick={() => {
                                                    this.setState({ fromDate: '' })
                                                    this.getData(token, '', toDate, servedBy, dispatch)
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-item">
                                    <div className="filter-item-label">по дату</div>
                                    <div className="filter-input-wrap">

                                        <div className="filter-input input-date">
                                            <Datetime
                                                timeFormat={false}
                                                locale='uk'
                                                defaultValue={''}
                                                value={toDate}
                                                onChange={e => {
                                                    this.setState({ toDate: new Date(e._d).setHours(23, 59, 59, 999) })
                                                    this.getData(token, fromDate, new Date(e._d).setHours(23, 59, 59, 999), servedBy, dispatch)

                                                }}
                                                inputProps={{ readOnly: true }}

                                            />
                                        </div>
                                        <div className="filter-input-clear">
                                            <i
                                                className="fas fa-times"
                                                onClick={() => {
                                                    this.setState({ toDate: '' })
                                                    this.getData(token, fromDate, '', servedBy, dispatch)
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-item">
                                    <div className="filter-item-label">людина</div>
                                    <div className="filter-input-wrap">

                                        <div className="filter-input input-date">
                                            <select
                                                required
                                                value={servedBy}
                                                onChange={e => {
                                                    this.setState({ servedBy: e.target.value })
                                                    this.getData(token, fromDate, toDate, e.target.value, dispatch)

                                                }}
                                            >
                                                {staff.length !== 0 && Array.isArray(staff) ? staff.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.name}>{item.name}</option>
                                                    )
                                                }) : null}
                                            </select>
                                        </div>
                                        <div className="filter-input-clear">
                                            <i
                                                className="fas fa-times"
                                                onClick={() => {
                                                    this.setState({ servedBy: '---' })
                                                    this.setState({ sortedData: [] })
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {dataCalculated && sortedData.length > 0 ?
                                <div className="table-statistics">
                                    <div className="table-statistics-row">
                                        <div className="table-statistics-row-label">Всього:</div>
                                        <div className="table-statistics-row-value">
                                            {totalSalary}
                                            <span>&#8372;</span>
                                        </div>
                                    </div>
                                </div>
                                : null}

                        </div>

                        <div className="Grid">

                            <div className="grid-wrap">

                                <div className="grid-row grid-row-header">
                                    <div className="grid-cell grid-cell-date">
                                        Дата
                                    </div>
                                    <div className="grid-cell grid-cell-textcetner">
                                        Прокатів
                                    </div>
                                    <div className="grid-cell grid-cell-textcetner">
                                        Каса/день
                                    </div>
                                    <div className="grid-cell grid-cell-textcetner">
                                        Каса/людина
                                    </div>
                                    <div className="grid-cell grid-cell-sallary">
                                        ЗП
                                    </div>
                                </div>


                                {dataCalculated ?

                                    sortedData.map((day, index) => {

                                        let date = new Date(day.date)

                                        let d = date.getDate();
                                        let m
                                        let y = date.getFullYear();

                                        if (date.getMonth().toString().length === 1) {
                                            m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                                        } else {
                                            m = date.getMonth() + 1
                                        }

                                        return (

                                            <div key={index} className="grid-row">
                                                <div className="grid-cell grid-cell-date">
                                                    {`${d}.${m}.${y}`}
                                                </div>
                                                <div className="grid-cell grid-cell-textcetner">
                                                    {day.personRents}
                                                </div>
                                                <div className="grid-cell grid-cell-textcetner">
                                                    {day.incomeDay}
                                                </div>
                                                <div className="grid-cell grid-cell-textcetner">
                                                    {day.personIncome}
                                                </div>
                                                <div className="grid-cell grid-cell-sallary">
                                                    {day.salary}
                                                    <span
                                                        className="grid-cell-extra"
                                                    >
                                                        {`${day.personIncome}*${this.state.fixedPersantage} + ${day.payoff}`}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })

                                    :
                                    <img
                                        className="preloader-s preloader-center"
                                        src={preloader}
                                        alt=""
                                    />
                                }

                            </div>
                        </div>

                    </>

                    : null}


            </div>
        )
    }
}

export default Calculation
