import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import './datetime.css';
import Nav from './components/Nav'
import Dashboard from './components/Dashboard'
import Table from './components/Table'
import Chart from './components/Chart'
import Income from './components/Income'
import Cost from './components/Cost'
import LoginPage from './components/LoginPage'
import PrivateRoute from './components/PrivateRoute'
import { Provider, Consumer } from './context'
import Location from './components/Location'
import SpareParts from './components/SpareParts'
import StorageNewItem from './components/StorageNewItem'
import StorageIncome from './components/StorageIncome'
import StorageCost from './components/StorageCost'
import Notification from './components/Notification'
import Calculation from './components/Calculation'
import WorkShifts from './components/WorkShifts'
import CustomerCard from './components/CustomerCard/CustomerCard';
import CustomerCardNew from './components/CustomerCard/CustomerCardNew';
import CustomerCardNewIncome from './components/CustomerCard/CustomerCardNewIncome';
import { CustomerCardNewCost } from './components/CustomerCard/CustomerCardNewCost';

require('moment/locale/uk');

class App extends Component {

    render() {
        return (
            <Provider>
                <Router>
                    <div className="App">
                        <Consumer>
                            {value => {
                                return (
                                    <Location dispatch={value.dispatch}>
                                        <Fragment>
                                            <Notification />
                                            {value.path !== '/login' && value.path !== '' ? <Nav /> : null}
                                            <Switch>

                                                <PrivateRoute exact path="/" component={Dashboard} />
                                                <PrivateRoute exact path="/table" component={Table} />
                                                <PrivateRoute exact path="/stats" component={Chart} />
                                                <PrivateRoute exact path="/new-income" component={Income} />
                                                <PrivateRoute exact path="/new-cost" component={Cost} />
                                                <PrivateRoute exact path="/spare-parts" component={SpareParts} />
                                                <PrivateRoute exact path="/storage-new-item" component={StorageNewItem} />
                                                <PrivateRoute exact path="/storage-income/:id" component={StorageIncome} />
                                                <PrivateRoute exact path="/storage-cost/:id" component={StorageCost} />
                                                <PrivateRoute exact path="/calculation" component={Calculation} />
                                                <PrivateRoute exact path="/work-shifts" component={WorkShifts} />
                                                <PrivateRoute exact path="/customer-cards" component={CustomerCard} />
                                                <PrivateRoute exact path="/customer-card-new" component={CustomerCardNew} />
                                                <PrivateRoute exact path="/customer-card-income/:id" component={CustomerCardNewIncome} />
                                                <PrivateRoute exact path="/customer-card-cost/:id" component={CustomerCardNewCost} />
                                                <Route path="/login" render={(props) => {
                                                    return <LoginPage {...props} dispatch={value.dispatch} />
                                                }} />

                                            </Switch>
                                        </Fragment>
                                    </Location>
                                )
                            }}
                        </Consumer>
                    </div>
                </Router>
            </Provider>
        )
    }
}

export default App;
