import React, { Component } from 'react'
import { Consumer } from '../context'

export class Notification extends Component {

    
    render() {
        
        return (
            <Consumer>
                {value=>{
                    const { notificationActive, notificationMessage, notificationType } = value
                    return (

                        <div className={notificationActive ? "Notification-wrap Notification-wrap-active" : "Notification-wrap"}>
                            <div className={
                                notificationType === "success" ?  
                                "Notification Notification-success" 
                                : (notificationType === "error" ? "Notification Notification-error" : "Notification" )}>
                                {notificationType === "success" ? 
                                    <i className="fas fa-check"></i>
                                    : 
                                    (
                                        notificationType === "error" ? 
                                        <i className="fas fa-times"></i>
                                        : null
                                    )
                                }
                                <div className="Notification-text">
                                    {notificationMessage}
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Consumer>
        )
    }
}

export default Notification
