import React, { Component } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Datetime from 'react-datetime'
import preloader from '../images/load.svg'
import axios from 'axios'



export class Chart extends Component {


    state = {
        data: [],
        times: [],
        fromDate: '',
        toDate: '',
        dataLoaded: false

    }


    getData = (token, fromDate, toDate, dispatch) => {

        this.setState({ dataLoaded: false })


        axios.get(
            `https://api-app.ecotrack.com.ua/api/income-records/?from_date=${fromDate === '' || isNaN(fromDate) ? '' : new Date(fromDate).toISOString()}&to_date=${toDate === '' || isNaN(toDate) ? '' : new Date(toDate).toISOString()}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then(response => {

                let weekdays = [
                    {
                        day: "НД",
                        rents: 0,
                    },
                    {
                        day: "ПН",
                        rents: 0,
                    },
                    {
                        day: "ВТ",
                        rents: 0,
                    },
                    {
                        day: "СР",
                        rents: 0,
                    },
                    {
                        day: "ЧТ",
                        rents: 0,
                    },
                    {
                        day: "ПТ",
                        rents: 0,
                    },
                    {
                        day: "СБ",
                        rents: 0,
                    }
                ]



                let times = Array.from({ length: 25 }, (_, i) => {

                    let step = 1800000
                    let startTime = 1577865600000
                    let date = new Date(startTime + step * i)

                    let time = `${date.getHours()}:${date.getMinutes() === 0 ? date.getMinutes() + "0" : date.getMinutes()}`


                    return {
                        time: time,
                        rents: 0
                    }

                })


                if (response.data.length !== 0) {

                    response.data.map(item => {

                        if (!item.deleted) {
                            weekdays[new Date(item.date).getDay()].rents += item.rents


                            let roundedTime = `${this.roundTimeHalfHour(item.date).getHours()}:${this.roundTimeHalfHour(item.date).getMinutes() === 0 ? this.roundTimeHalfHour(item.date).getMinutes() + "0" : this.roundTimeHalfHour(item.date).getMinutes()}`

                            if (times.some(e => e.time === roundedTime)) {
                                return times.map((i, idx) => {
                                    if (i.time === roundedTime) {
                                        return times[idx].rents += item.rents
                                    } else return null
                                })
                            } else return null

                        } else return null

                    })

                }
                this.setState({ data: weekdays })
                this.setState({ times })
                this.setState({ dataLoaded: true })


            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                    dispatch({ type: "LOGOUT", payload: '' })
                }
            }
            )


    }

    roundTimeHalfHour = time => {
        let timeToReturn = new Date(time);

        timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
        timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 30) * 30);
        return timeToReturn;
    }

    setFromDate = (data) => {
        this.setState({ fromDate: data })
    }
    setToDate = (data) => {
        this.setState({ toDate: data })
    }



    componentDidMount() {
        const { token, dispatch } = this.props
        const { fromDate, toDate } = this.state
        this.getData(token, fromDate, toDate, dispatch)

    }


    render() {
        const { fromDate, toDate, data, times, dataLoaded } = this.state
        const { token, dispatch, isSuperUser } = this.props

        if (!isSuperUser) {
            return null;
        }


        return (
            <div className="Chart">
                <div className="page-title">
                    Статистика
                </div>
                <div className="table-filter">
                    <div className="filter-item">
                        <div className="filter-item-label">з дати</div>
                        <div className="filter-input-wrap">
                            <div className="filter-input input-date">
                                <Datetime
                                    timeFormat={false}
                                    locale='uk'
                                    defaultValue={''}
                                    value={fromDate}
                                    onChange={e => {
                                        this.setState({ fromDate: new Date(e._d).setHours(0, 0, 0, 0) })
                                        this.getData(token, new Date(e._d).setHours(0, 0, 0, 0), toDate, dispatch)
                                    }}
                                    inputProps={{ readOnly: true }}

                                />
                            </div>
                            <div className="filter-input-clear">
                                <i
                                    className="fas fa-times"
                                    onClick={() => {
                                        this.setState({ fromDate: '' })
                                        this.getData(token, '', toDate, dispatch)

                                    }}
                                ></i>
                            </div>
                        </div>
                    </div>
                    <div className="filter-item">
                        <div className="filter-item-label">по дату</div>
                        <div className="filter-input-wrap">

                            <div className="filter-input input-date">
                                <Datetime
                                    timeFormat={false}
                                    locale='uk'
                                    defaultValue={''}
                                    value={toDate}
                                    onChange={e => {
                                        this.setState({ toDate: new Date(e._d).setHours(23, 59, 59, 999) })
                                        this.getData(token, fromDate, new Date(e._d).setHours(23, 59, 59, 999), dispatch)

                                    }}
                                    inputProps={{ readOnly: true }}

                                />
                            </div>
                            <div className="filter-input-clear">
                                <i
                                    className="fas fa-times"
                                    onClick={() => {
                                        this.setState({ toDate: '' })
                                        this.getData(token, fromDate, '', dispatch)

                                    }}
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>

                {dataLoaded && data.length !== 0 ?

                    <>
                        <div className="chart-wrap chart-day">

                            <LineChart
                                width={1000}
                                height={300}
                                data={data}
                                margin={{
                                    top: 10, right: 10, left: 10, bottom: 10,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis stroke="#0000000" dataKey="day" />
                                <YAxis stroke="#0000000" />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="rents"
                                    stroke="#34b44a"
                                    strokeWidth="3"
                                    animationDuration={1000}
                                    activeDot={{ r: 8 }}
                                    left={10}
                                />
                            </LineChart>
                        </div>

                        <div className="chart-wrap chart-time">

                            <LineChart
                                width={1000}
                                height={300}
                                data={times}
                                margin={{
                                    top: 10, right: 10, left: 10, bottom: 10,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis stroke="#0000000" dataKey="time" />
                                <YAxis stroke="#0000000" />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="rents"
                                    stroke="#34b44a"
                                    strokeWidth="3"
                                    animationDuration={1000}
                                    activeDot={{ r: 8 }}
                                    left={10}
                                />
                            </LineChart>
                        </div>
                    </>

                    :
                    <img
                        className="preloader-s preloader-center"
                        src={preloader}
                        alt=""
                    />
                }


            </div>
        )
    }
}

export default Chart
