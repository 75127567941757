import React, { Component } from 'react'
import logo from '../images/logo.png'
import { Consumer } from '../context'
import { Redirect } from 'react-router-dom'



export class LoginPage extends Component {

    state = {
        username: '',
        password: '',
        incorrectAuth: false
    }


    loginAction = (e, dispatch) => {
        e.preventDefault()

        let data = {
            username: this.state.username,
            password: this.state.password
        }

        this.setState({ incorrectAuth: false })


        fetch('https://api-app.ecotrack.com.ua/api/auth/signin', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ incorrectAuth: true })
                    throw new Error(response.statusText)
                }
                else {
                    this.setState({ incorrectAuth: false })
                    return response.json()
                }
            })
            .then(data => {
                localStorage.setItem("token", data.token);
                localStorage.setItem("user", JSON.stringify(data.user));
                dispatch({ type: "LOGIN", payload: data })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const { username, password, incorrectAuth } = this.state


        return (
            <Consumer>
                {value => {
                    const { dispatch, isLogged } = value

                    if (!isLogged) {

                        return (

                            <div className="LoginPage">
                                <form
                                    className="login-form"
                                    onSubmit={e => {
                                        this.loginAction(e, dispatch)
                                    }}
                                >
                                    <div className="login-form-row">
                                        <img src={logo} alt="" />
                                    </div>
                                    <div className="login-form-row">
                                        <div className="row-label">Логін</div>
                                        <input
                                            required
                                            type="text"
                                            className={!incorrectAuth ? 'row-input' : 'row-input row-input-wrong'}
                                            value={username}
                                            onChange={e => {
                                                this.setState({ username: e.target.value })
                                            }}

                                        />
                                    </div>
                                    <div className="login-form-row">
                                        <div className="row-label">Пароль</div>
                                        <input
                                            required
                                            type="password"
                                            className={!incorrectAuth ? 'row-input' : 'row-input row-input-wrong'}
                                            value={password}
                                            onChange={e => {
                                                this.setState({ password: e.target.value })
                                            }}

                                        />
                                    </div>
                                    <button
                                        type="submit"
                                    >
                                        Увійти
                                    </button>
                                </form>
                            </div>
                        )
                    } else {
                        return <Redirect to='' />
                    }
                }}
            </Consumer>

        )
    }
}

export default LoginPage
