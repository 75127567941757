import React, { Component } from 'react'
import axios from 'axios';
import preloader from '../images/load.svg'


export class StorageNewItem extends Component {

    state = {
        itemName: '',
        link: '',
        code: '',
        image: '',
        isItemNameValid: false,
        isLinkValid: true,
        recordAdding: false
    }

    postRecord = () => {
        const { itemName, link, code, image } = this.state

        let formData = new FormData()
        const { token, showNotification } = this.props
        
        formData.append('image', image)
        formData.append('name', itemName)
        formData.append('code', code)
        formData.append('link', link)

        this.setState({recordAdding: true})

        setTimeout(() => {
            
            axios.post('https://api-app.ecotrack.com.ua/api/storage-items/', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Token ${token}`
    
                }
            })
            .then(() => {
                
                this.setState({code: ''})
                this.setState({itemName: ''})
                this.setState({link: ''})
                this.setState({image: ''})
                this.setState({isItemNameValid: false})
                this.setState({isLinkValid: false})
                this.setState({recordAdding: false})
                this.fileInput.value = ''

                showNotification('додано успішно', 'success')                
                
            })
            .catch((response)=>{
                this.setState({code: ''})
                this.setState({itemName: ''})
                this.setState({link: ''})
                this.setState({image: ''})
                this.setState({isItemNameValid: false})
                this.setState({isLinkValid: false})
                this.setState({recordAdding: false})
                this.fileInput.value = ''

                showNotification('помилка', 'error')                

                return new Error(response.status)
            })
        }, 3000);


    }

    render() {

        const { itemName, link, code, isLinkValid, isItemNameValid, recordAdding } = this.state
        return (
            <div className="StorageNewItem">
                <div className="page-title">Нова запчастина</div>

                <form
                    onSubmit={e=>{
                        e.preventDefault()
                        this.postRecord()
                    }}
                >

                    <div className="income-form">
                        <div className="form-row">
                            <div className="form-row-label">Код</div>
                            <div className="form-row-input">
                                <input 
                                    type="text"
                                    value={code}
                                    onChange={e=>{
                                        this.setState({code: e.target.value})
                                    }}
                                    placeholder="#6828A"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Назва</div>
                            <div className="form-row-input">
                                <input 
                                    type="text"
                                    value={itemName}
                                    onChange={e=>{
                                        this.setState({itemName: e.target.value})
                                        this.setState({isItemNameValid: e.target.checkValidity()})

                                    }}
                                    required
                                    placeholder="Differential output"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label">Посилання на сайт</div>
                            <div className="form-row-input">
                                <input 
                                    type="url"
                                    value={link}
                                    onChange={e=>{
                                        this.setState({link: e.target.value})
                                        this.setState({isLinkValid: e.target.checkValidity()})
                                    }}
                                    placeholder="https://traxxas.com/products/parts/6828A"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label">Зображення</div>
                            <div className="form-row-input">
                                <input 
                                    type="file"
                                    ref={ref=> this.fileInput = ref} 
                                    onChange={e=>{
                                        this.setState({image: e.target.files[0]})
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="add-record"
                        disabled={ isLinkValid && isItemNameValid && !recordAdding ? false : true}
                    >
                        {recordAdding ? 
                            <img
                                className="preloader-s preloader-center"
                                src={preloader}
                                alt=""
                            />
                        : 
                            'Додати'
                        }
                    </button>
                    
                </form>

            </div>
        )
    }
}

export default StorageNewItem
