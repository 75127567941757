import React, { Component } from 'react'
import axios from 'axios';
import preloader from '../../images/load.svg'


export class CustomerCardNew extends Component {

    state = {
        customerName: '',
        customerPhone: '',
        recordAdding: false
    }

    postRecord = () => {
        const { customerPhone, customerName } = this.state

        let formData = new FormData()
        const { token, showNotification } = this.props

        if (customerName === '' || customerPhone === '') {

            showNotification('Не всі поля заповнені', 'error')
            return false
        }

        formData.append('customer_name', customerName)
        formData.append('customer_phone', customerPhone)

        this.setState({ recordAdding: true })

        axios.post(`https://api-app.ecotrack.com.ua/api/customercards-items/`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Token ${token}`

            }
        })
            .then(() => {

                this.setState({ customerName: '' })
                this.setState({ customerPhone: '' })
                this.setState({ recordAdding: false })

                showNotification('додано успішно', 'success')

            })
            .catch((response) => {
                this.setState({ customerName: '' })
                this.setState({ customerPhone: '' })
                this.setState({ recordAdding: false })

                showNotification('помилка', 'error')

                return new Error(response.status)
            })


    }

    render() {

        const { customerName, customerPhone, recordAdding } = this.state
        return (
            <div className="StorageNewItem">
                <div className="page-title">Нова картка</div>

                <form
                    onSubmit={e => {
                        e.preventDefault()
                        this.postRecord()
                    }}
                >

                    <div className="income-form">
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Імʼя</div>
                            <div className="form-row-input">
                                <input
                                    type="text"
                                    value={customerName}
                                    onChange={e => {
                                        this.setState({ customerName: e.target.value })
                                    }}
                                    required
                                    placeholder="Віталій"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-row-label form-row-required">Номер телефону</div>
                            <div className="form-row-input">
                                <input
                                    type="text"
                                    value={customerPhone}
                                    onChange={e => {
                                        this.setState({ customerPhone: e.target.value })
                                    }}
                                    required
                                    placeholder="+380671234567"
                                />
                            </div>
                        </div>

                    </div>
                    <button
                        type="submit"
                        className="add-record"
                        disabled={!recordAdding ? false : true}
                    >
                        {recordAdding ?
                            <img
                                className="preloader-s preloader-center"
                                src={preloader}
                                alt=""
                            />
                            :
                            'Додати'
                        }
                    </button>

                </form>

            </div>
        )
    }
}

export default CustomerCardNew
