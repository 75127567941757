import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CustomerCardItem from './CustomerCardItem'
import preloader from '../../images/load.svg'


export class CustomerCard extends Component {


    state = {
        data: '',
        dataLoaded: false,
        isMobile: false,
        search: ''
    }


    componentDidMount() {

        window.addEventListener('resize', e => {
            if (e.target.outerWidth <= 550) {
                this.setState({ isMobile: true })
            } else { this.setState({ isMobile: false }) }
        })
        if (window.outerWidth <= 550) {
            this.setState({ isMobile: true })
        }

        const { token, dispatch } = this.props

        fetch(`https://api-app.ecotrack.com.ua/api/customercards-items/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {

                        localStorage.removeItem('token')
                        localStorage.removeItem('user_id')
                        dispatch({ type: "LOGOUT", payload: '' })

                    }
                    throw new Error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                this.setState({ data })
                this.setState({ dataLoaded: true })
            })
            .catch(error => {
                console.log(error)
            })

    }

    render() {

        const { data, dataLoaded, isMobile, search } = this.state


        return (
            <div className="SpareParts">
                <div className="page-title">Картки клієнтів</div>
                <div className="page-actions">
                    <Link to='customer-card-new'>Додати</Link>
                </div>

                <div className="search-block">
                    <div className="input-main">
                        <input
                            type="text"
                            placeholder="Пошук по імені або номеру телефону"
                            value={search}
                            onChange={e => this.setState({ search: e.target.value.toLowerCase() })}
                        />

                        {search !== '' && (
                            <button
                                onClick={() => this.setState({ search: '' })}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        )}

                    </div>

                </div>

                <div className="Grid SparePartsGrid" style={{ marginTop: '30px' }}>

                    <div className="spare-parts-grid grid-wrap">

                        {!isMobile ?
                            <div className="grid-row grid-row-header">
                                <div className="grid-cell grid-cell-name">
                                    Гість
                                </div>
                                <div className="grid-cell grid-cell-name">
                                    Номер тел
                                </div>
                                <div className="grid-cell grid-cell-amount grid-cell-textcetner">
                                    Баланс
                                </div>
                                <div className="grid-cell grid-cell-descr"></div>
                                <div className="grid-cell grid-cell-actions"></div>
                            </div>
                            : null}

                        {data !== '' && dataLoaded ?

                            data.map((item, index) => {

                                if (search !== '') {

                                    if (item.customer_name.toLowerCase().includes(search.toLowerCase()) || item.customer_phone.toLowerCase().includes(search.toLowerCase())) {
                                        return (
                                            <CustomerCardItem
                                                key={index}
                                                data={item}
                                                isMobile={isMobile}
                                                search={search}
                                            />
                                        )
                                    } else {
                                        return null
                                    }
                                } else {
                                    return (
                                        <CustomerCardItem
                                            key={index}
                                            data={item}
                                            isMobile={isMobile}
                                            search={search}
                                        />
                                    )
                                }

                            })


                            :

                            <img
                                className="preloader-s preloader-center"
                                src={preloader}
                                alt=""
                            />

                        }




                    </div>
                </div>

            </div>
        )
    }
}

export default CustomerCard
