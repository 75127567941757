import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from "react-router";
import logo from '../images/logo.png'
import { Consumer } from '../context'


export class Nav extends Component {


    state = {
        isMobile: false,
        navExtraActive: false
    }


    logOut = (token, dispatch) => {

        fetch(`https://api-app.ecotrack.com.ua/api/auth/logout`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(() => {
                dispatch({ type: "LOGOUT", payload: '' })
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                this.props.history.push('/login')

            })

    }

    handleOutsideClick = e => {

        let navExtraBlock = document.querySelector('.nav-extra')
        let path = e.path || (e.composedPath && e.composedPath());

        if (navExtraBlock && path) {
            if (!path.includes(navExtraBlock)) {
                this.setState({ navExtraActive: false })
            }
        }

    }

    componentWillUnmount = () => {
        document.querySelector('body').removeEventListener('click', this.handleOutsideClick)
    };


    componentDidMount() {

        document.querySelector('body').addEventListener('click', this.handleOutsideClick)


        window.addEventListener('resize', e => {
            if (e.target.outerWidth <= 550) {
                this.setState({ isMobile: true })
            } else { this.setState({ isMobile: false }) }
        })
        if (window.outerWidth <= 550) {
            this.setState({ isMobile: true })
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ navExtraActive: false })
        }
    }


    render() {
        return (

            <Consumer>
                {value => {
                    const { user, token, dispatch, isSuperUser } = value
                    const { isMobile, navExtraActive } = this.state
                    return (

                        <div className="Nav">
                            <div className="nav-logo">
                                <img src={logo} alt="" />
                            </div>

                            <div className="nav-list">
                                <div className="nav-link">
                                    <Link to="/">
                                        <i className="fas fa-calendar"></i>
                                        <span>Сьогодні</span>
                                    </Link>
                                </div>
                                {isSuperUser ?
                                    <div className="nav-link">
                                        <Link to="/table">
                                            <i className="fas fa-table"></i>
                                            <span>Таблиця</span>
                                        </Link>
                                    </div>
                                    : null}

                                <div className="nav-link">
                                    <Link to="/customer-cards">
                                        <i className="fas fa-user"></i>
                                        <span>Клієнти</span>
                                    </Link>
                                </div>

                                {
                                    isMobile ?

                                        <>

                                            <div className="nav-link">
                                                <div
                                                    onClick={() => this.setState({ navExtraActive: true })}
                                                >
                                                    <i className="fas fa-ellipsis-h"></i>
                                                    <span>Більше</span>
                                                </div>
                                            </div>

                                            {navExtraActive ?

                                                <div className="nav-extra">
                                                    <div className="nav-extra-link nav-logout">
                                                        <div className="nav-extra-user">
                                                            {user ? user.username : null}
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                this.logOut(token, dispatch)
                                                            }}
                                                        >
                                                            <i className="fas fa-power-off"></i>
                                                        </div>
                                                    </div>

                                                    {user && isSuperUser ?

                                                        <div className="nav-extra-link">
                                                            <Link to="/calculation">
                                                                <i className="fas fa-calculator"></i>
                                                                <span>Розрахунок</span>
                                                            </Link>
                                                        </div>

                                                        : null}


                                                    <div className="nav-extra-link">
                                                        <Link to="/spare-parts">
                                                            <i className="fas fa-cog"></i>
                                                            <span>Запчастини</span>
                                                        </Link>
                                                    </div>

                                                    <div className="nav-extra-link">
                                                        <Link to="/work-shifts">
                                                            <i className="fas fa-user-clock"></i>
                                                            <span>Зміни</span>
                                                        </Link>
                                                    </div>

                                                    {isSuperUser ?
                                                        <div className="nav-extra-link">
                                                            <Link to="/stats">
                                                                <i className="fas fa-chart-line"></i>
                                                                <span>Статистика</span>
                                                            </Link>
                                                        </div>
                                                        : null}
                                                </div>
                                                : null}

                                        </>

                                        :

                                        <>



                                            <div className="nav-link">
                                                <Link to="/spare-parts">
                                                    <i className="fas fa-cog"></i>
                                                    <span>Запчастини</span>
                                                </Link>
                                            </div>

                                            <div className="nav-link">
                                                <Link to="/work-shifts">
                                                    <i className="fas fa-user-clock"></i>
                                                    <span>Зміни</span>
                                                </Link>
                                            </div>

                                            {isSuperUser ?
                                                <div className="nav-link">
                                                    <Link to="/stats">
                                                        <i className="fas fa-chart-line"></i>
                                                        <span>Статистика</span>
                                                    </Link>
                                                </div>
                                                : null}

                                            {isSuperUser ?

                                                <div className="nav-link">
                                                    <Link to="/calculation">
                                                        <i className="fas fa-calculator"></i>
                                                        <span>Розрахунок</span>
                                                    </Link>
                                                </div>

                                                : null}

                                            <div className="nav-bottom">
                                                <div className="nav-user">
                                                    {user ? user.username : null}
                                                </div>
                                                <div
                                                    className="nav-link nav-link-signout"
                                                    onClick={() => {
                                                        this.logOut(token, dispatch)
                                                    }}
                                                >
                                                    <i className="fas fa-power-off"></i>
                                                    <span>Вийти</span>
                                                </div>
                                            </div>

                                        </>
                                }

                            </div>
                        </div>
                    )
                }}
            </Consumer>
        )
    }
}

export default withRouter(Nav)
