import React, { Component } from 'react'
import axios from 'axios'
import preloader from '../images/load.svg'
import WorkShiftsCard from './WorkShiftsCard'


export class Book extends Component {

    state = {
        today: '',
        workShiftsLoading: false,
        week: '',
        staff: ''
    }

    componentDidMount(){
        const { token, dispatch } = this.props
        let today = new Date(new Date().setHours(0,0,0,0)).toISOString()
        this.setState({today})
        this.getWorkShifts(today, token, dispatch)
    }


    makeWorkShiftsLoading = () => {
        this.setState({workShiftsLoading: true})
    }


    getStaffList = (token, dispatch) => {
        fetch(`https://api-app.ecotrack.com.ua/api/staff/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(!response.ok){
                if(response.status === 401){

                    localStorage.removeItem('token')
                    localStorage.removeItem('user_id')
                    dispatch({type: "LOGOUT", payload: ''})
    
                }
                throw new Error(response.status)
            } else {
                return response.json()
            }
        })
        .then(data => {
            this.setState({staff: data})
        })
        .catch(error => {
            console.log(error);
        })
    }


    generateWeek = (dateFrom, data) => {

        dateFrom = new Date(dateFrom).getTime();

        let week = Array.from({length: 14}, (_,i)=>{

            let day = new Date(dateFrom + (i * 86400000)).setHours(0,0,0,0)
            let staff = []  

            data.forEach(recordDay=>{
                let recordDayDate = new Date(recordDay.date_from).setHours(0,0,0,0)

                if(recordDayDate === day){
                    staff.push(
                            {
                                staff: recordDay.staff, 
                                time_from: recordDay.date_from,  
                                time_to: recordDay.date_to,
                                record_id: recordDay.id
                            }
                        )
                }
            })

        
            return {
                day: day,
                staffList: staff
            }
        })

        this.setState({week})
        this.setState({workShiftsLoading: false})

    }

    getWorkShifts = (fromDate, token, dispatch) => {

        this.setState({workShiftsLoading: true})

        fromDate = new Date(fromDate).toISOString()
        let toDate = new Date(new Date(fromDate).getTime() + 1209600000).toISOString()
        
        axios.get(
            `https://api-app.ecotrack.com.ua/api/work-shifts/?from_date=${fromDate}&to_date=${toDate}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then(response=>{
                this.getStaffList(token, dispatch)
                this.generateWeek(fromDate, response.data)
            })
            .catch(error =>{
                if (error.response){
                    if (error.response.status === 401){
                        localStorage.removeItem('token')
                        localStorage.removeItem('user')
                        dispatch({type: "LOGOUT", payload: ''})
                    }
                }
            }
        )
    }

    

    render() {
        const { workShiftsLoading, week, today, staff } = this.state
        const { token, dispatch } = this.props

        return (
            <div className="Book">
                <div className="page-title">Зміни</div>

                {!workShiftsLoading && week !== '' ? 

                    <div className="workShifts">
                        {week.map((day, index) => {
                            return (

                                <WorkShiftsCard 
                                    day={day}
                                    key={index}
                                    token={token}
                                    dispatch={dispatch}
                                    today={today}
                                    getWorkShifts={this.getWorkShifts}
                                    staff={staff}
                                    makeWorkShiftsLoading={this.makeWorkShiftsLoading}
                                />

                            )
                        })}
                    </div>
                    
                :
                
                <img
                    className="preloader-s preloader-center"
                    src={preloader}
                    alt=""
                />
                }
            </div>
        )
    }
}

export default Book
