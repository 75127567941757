import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Consumer } from '../context'


const PrivateRoute = ({component: Component, ...rest}) => (
    <Consumer>
        {value=>{
            return (
                <Route 
                    {...rest}
                    render={props=>{
                        if(!value.isLogged){
                            return <Redirect to="/login"/>
                        }else {
                            return <Component {...props} {...value}/>
                        }
                    }}
                />
            )
        }}
    </Consumer>
);

export default PrivateRoute
