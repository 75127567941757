import React, { Component } from 'react'
import Datetime from 'react-datetime'
import preloader from '../images/load.svg'
import { Consumer } from '../context'


export class Income extends Component {
    state = {
        datetime: '',
        rents: '',
        servedBy: '---',
        money: '',
        description: '',
        staff: '',
        card_paid: false,
        isRentsValid: false,
        isMoneyValid: false,
        recordAdding: false,
        staffLoaded: false,
        tariffPrice: ''

    }

    componentDidMount() {
        let now = new Date()
        this.setState({ datetime: now })
        this.getTariffPrice()

        const { token, dispatch } = this.props

        fetch(`https://api-app.ecotrack.com.ua/api/staff/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {

                        localStorage.removeItem('token')
                        localStorage.removeItem('user_id')
                        dispatch({ type: "LOGOUT", payload: '' })

                    }
                    throw new Error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                this.setState({ staff: data })
                this.setState({ staffLoaded: true })
            })
            .catch(error => {
                console.log(error);
            })


    }

    postRecord = (dispatch) => {
        const { datetime, rents, servedBy, money, description, card_paid } = this.state
        const { token, showNotification } = this.props

        let date = new Date(datetime).toISOString()

        this.setState({ recordAdding: true })

        let data = {
            "served_by": {
                "name": servedBy === '' ? null : servedBy
            },
            "date": date,
            "rents": rents,
            "money": money,
            "card_paid": card_paid,
            "description": description
        }


        fetch('https://api-app.ecotrack.com.ua/api/income-records/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ rents: '' })
                    this.setState({ servedBy: '---' })
                    this.setState({ money: '' })
                    this.setState({ description: '' })
                    this.setState({ card_paid: false })
                    this.setState({ isRentsValid: false })
                    this.setState({ isMoneyValid: false })
                    this.setState({ recordAdding: false })

                    showNotification('помилка', 'error')

                    return new Error(response.status)
                }
                else {
                    this.setState({ rents: '' })
                    this.setState({ servedBy: '---' })
                    this.setState({ money: '' })
                    this.setState({ description: '' })
                    this.setState({ card_paid: false })
                    this.setState({ isRentsValid: false })
                    this.setState({ isMoneyValid: false })
                    this.setState({ recordAdding: false })

                    showNotification('додано успішно', 'success')

                    this.props.history.push('/')

                }
            })

    }


    getMonth = (date) => {
        let months = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
        return months[new Date(date).getMonth()]
    }

    getTariffPrice = () => {
        let now = new Date()

        this.setState({
            tariffPrice: now.getDay() === 6 || now.getDay() === 0 ? 250 : 200
        })
    }

    render() {

        const { datetime, rents, servedBy, staff, money, description, isMoneyValid, isRentsValid, recordAdding, card_paid, staffLoaded, tariffPrice } = this.state

        return (

            <Consumer>
                {value => {
                    const { dispatch, isSuperUser } = value
                    return (

                        <div className="Income">
                            <div className="page-title">Новий дохід</div>

                            <form
                                onSubmit={e => {
                                    e.preventDefault()
                                    this.postRecord(dispatch)
                                }}
                            >

                                <div className="income-form">
                                    <div className="form-row">
                                        <div className="form-row-label form-row-required">{isSuperUser ? 'Дата' : 'Час'}</div>
                                        <div className="form-row-input input-date">
                                            <Datetime
                                                locale='uk'
                                                defaultValue={new Date()}
                                                value={datetime}
                                                onChange={e => {
                                                    this.setState({ datetime: new Date(e._d) })
                                                }}
                                                dateFormat={isSuperUser}
                                                inputProps={{ readOnly: true }}

                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-row-label form-row-required">Обслуговував</div>
                                        <div className="form-row-input">
                                            {staffLoaded ?

                                                <select
                                                    required
                                                    value={servedBy}
                                                    onChange={e => {
                                                        this.setState({ servedBy: e.target.value })
                                                        this.setState({ isServedByValid: e.target.checkValidity() })
                                                    }}
                                                >
                                                    {staff.length !== 0 && Array.isArray(staff) ? staff.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.name}>{item.name}</option>
                                                        )
                                                    }) : null}
                                                </select>
                                                :
                                                <img
                                                    className="preloader-s"
                                                    src={preloader}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-row-label">Безготівковий</div>
                                        <div className="form-row-input">
                                            <button
                                                type='button'
                                                className={card_paid ? "input-checkbox input-checked" : "input-checkbox"}
                                                onClick={() => { this.setState({ card_paid: !this.state.card_paid }) }}
                                            >
                                                {card_paid ? <i className="fas fa-check checkbox-checked"></i> : <i className="fas fa-check"></i>}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-row-label form-row-required">Прокатів</div>
                                        <div className="form-row-input">
                                            <input
                                                required
                                                type="number"
                                                pattern="\d*"
                                                min="0"
                                                step="1"
                                                title="Тільки позитивні числа"
                                                value={rents}
                                                onChange={e => {
                                                    this.setState({ money: e.target.value * tariffPrice })
                                                    this.setState({ rents: e.target.value })
                                                    this.setState({ isRentsValid: e.target.checkValidity() })
                                                    this.setState({ isMoneyValid: true })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-row-label form-row-required">Сума</div>
                                        <div className="form-row-input">
                                            <input
                                                required
                                                type="number"
                                                pattern="\d*"
                                                min="0"
                                                step="1"
                                                title="Тільки позитивні числа"
                                                value={money}
                                                onChange={e => {
                                                    this.setState({ money: e.target.value })
                                                    this.setState({ isMoneyValid: e.target.checkValidity() })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-row-label">Комент</div>
                                        <div className="form-row-input">
                                            <input
                                                type="text"
                                                value={description}
                                                onChange={e => {
                                                    this.setState({ description: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="add-record"
                                    disabled={isMoneyValid && isRentsValid && !recordAdding ? false : true}
                                >
                                    {recordAdding ?
                                        <img
                                            className="preloader-s preloader-center"
                                            src={preloader}
                                            alt=""
                                        />
                                        :
                                        'Додати'
                                    }
                                </button>




                            </form>

                        </div>
                    )
                }}
            </Consumer>
        )
    }
}

export default Income
